import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { Router, Route, Switch } from 'react-router'
import Loadable from 'react-loadable'
import { GlobalStyles } from './styles/global-styles'
import { Icomoon } from './styles/icomoon'
import { Normalize } from './styles/normalize'
import { Slick } from './styles/slick'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import * as Sentry from '@sentry/react'

if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.MT_ENV
    })
}

library.add(fas)

import './i18n'

function Loading() {
    return <div></div>
}

const Base = Loadable({
    loader: () => import('./containers/Base'),
    loading: Loading
})

const ChatContainer = Loadable({
    loader: () => import('./containers/ChatContainer'),
    loading: Loading
})

const ErrorContainer = Loadable({
    loader: () => import('./containers/ErrorContainer'),
    loading: Loading
})

const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()
const history = syncHistoryWithStore(browserHistory, routingStore)
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

Loadable.preloadReady().then(() => {
    renderMethod(
        <Provider routing={routingStore}>
            <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
                <div>
                    <GlobalStyles />
                    <Icomoon />
                    <Normalize />
                    <Slick />

                    <Switch>
                        <Route path="/:appCodename/:token" component={Base} />
                        <Route component={ErrorContainer} />
                    </Switch>
                    <Switch>
                        <Route exact path="/:appCodename/:token/chat" component={ChatContainer} />
                        <Route exact path="/:appCodename/:token/error" component={ErrorContainer} />
                    </Switch>
                </div>
            </Router>
        </Provider>,
        document.getElementById('root')
    )
})

