const themes = {
    default: {
        colors: {
            primary: '#FA5D48',
            secondary: '#7E93AF',
            header: '#FA5D48',
            headerButtons: '#FFFFFF',
            message: '#666666',
            send: '#FA5D48'
        },
        font: {
            name: 'sans-serif',
            color: '#FFFFFF',
            url: '',
            size: 13,
            padding: 12
        },
        backgroundImage: [
            {
                fullURL: ''
            }
        ],
        avatar: false,
        buttons: {
            attachments: false,
            emojis: true,
            audio: true
        },
        header: {
            size: 75
        },
        icon: {
            width: 42,
            height: 42,
            sizeSend: 42,
            sizeMicrophone: 25,
            sizeEmoji: 42,
            sizeSecondary: 22
        }
    }
}

export default themes
