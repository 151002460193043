import { createGlobalStyle } from 'styled-components'
import themes from './themes'

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Gotham';
        src: url('/static/fonts/Gotham/Gotham-Bold.ttf') format('truetype');
        font-weight: bold;
    }

    @font-face {
        font-family: 'Gotham';
        src: url('/static/fonts/Gotham/Gotham-Medium.ttf') format('truetype');
        font-weight: 500;
    }

    @font-face {
        font-family: 'Gotham';
        src: url('/static/fonts/Gotham/Gotham-Book.ttf') format('truetype');
        font-weight: normal;
    }

    @font-face {
        font-family: 'Gotham';
        src: url('/static/fonts/Gotham/Gotham-Light.ttf') format('truetype');
        font-weight: 300;
    }

    @font-face {
        font-family: 'Benton Sans';
        src: url('/static/fonts/Benton-Sans/BentonSans-Bold.otf');
        font-weight: bold;
    }

    @font-face {
        font-family: 'Benton Sans';
        src: url('/static/fonts/Benton-Sans/BentonSans-Medium.otf');
        font-weight: 500;
    }

    @font-face {
        font-family: 'Benton Sans';
        src: url('/static/fonts/Benton-Sans/BentonSans-Regular.otf');
        font-weight: normal;
    }

    @font-face {
        font-family: 'Benton Sans';
        src: url('/static/fonts/Benton-Sans/BentonSans-Book.otf');
        font-weight: 300;
    }

    .app {
        width: 100%;
        background: ${themes.default.colors.white};
    }
    .arrow {
        margin: 0px 3px;
        position: relative;
        top: 0.4em;
        border-radius: 3px;
        border: 1px solid ${themes.default.colors.secondary};
        background-color: ${themes.default.colors.white};
        color: ${themes.default.colors.secondaryDark};
        padding: 10px 12px;
        outline: 0px;
        cursor: pointer;
        & > span {
            font-size: 15px;
            vertical-align: middle;
        }
    }
    .slider-decorator-0 {
        top: 0px !important;
        left: 0px !important;
        height: 100% !important;
    }
    .slider-decorator-1 {
        top: 0px !important;
        left: auto !important;
        right: 0px !important;
        height: 100% !important;
    }
    .slider {
        min-height: 50px  !important;
        max-height: 140px !important;
    }

    ::-webkit-scrollbar {
        display: none;
    }
`
