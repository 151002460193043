import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationDE from './locales/de/translation.json'
import translationES from './locales/es/translation.json'
import translationEN from './locales/en/translation.json'
import translationFR from './locales/fr/translation.json'
import translationNL from './locales/nl/translation.json'
import translationPT from './locales/pt/translation.json'

import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'

// the translations
const resources = {
    de: {
        translation: translationDE
    },
    es: {
        translation: translationES
    },
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    nl: {
        translation: translationNL
    },
    pt: {
        translation: translationPT
    }
}

let navLanguage = navigator.language || navigator.userLanguage
navLanguage = navLanguage.split('-')[0]

if (Object.keys(resources).indexOf(navLanguage) < 0) {
    navLanguage = 'es'
}

i18n
    .use(LanguageDetector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        detection: {
            // order and from where user language should be detected
            order: ['querystring'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: process.env.PUBLIC_URL,
            cookieOptions: ((process.env.MT_ENV === 'development') && { path: '/', sameSite: 'strict' }) || { path: '/', sameSite: 'strict', secure: true }, // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement
        },
        fallbackLng: navLanguage,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

export default i18n
