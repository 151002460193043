import { createGlobalStyle } from 'styled-components'

export const Icomoon = createGlobalStyle`
    @font-face {
        font-family: 'icomoon';
        src:  url('/static/icomoon/icomoon.eot?loaah');
        src:  url('/static/icomoon/icomoon.eot?loaah#iefix') format('embedded-opentype'),
        url('/static/icomoon/icomoon.ttf?loaah') format('truetype'),
        url('/static/icomoon/icomoon.woff?loaah') format('woff'),
        url('/static/icomoon/icomoon.svg?loaah#icomoon') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    [class^="icon-"], [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-down:before {
      content: "\\e909";
    }
    .icon-up:before {
      content: "\\e90a";
    }
    .icon-typing:before {
      content: "\\e903";
    }
    .icon-send:before {
      content: "\\e902";
    }
    .icon-logout:before {
      content: "\\e906";
    }
    .icon-attach:before {
      content: "\\e900";
    }
    .icon-emoji:before {
      content: "\\e901";
    }
    .icon-check:before {
      content: "\\e904";
    }
    .icon-close:before {
      content: "\\e905";
    }
    .icon-next:before {
      content: "\\e907";
    }
    .icon-prev:before {
      content: "\\e908";
    }
`
